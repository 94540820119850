import { type AxiosError } from 'axios';
import {
  addKidToGroup,
  createGroup,
  deleteGroupMembership,
  editGroup,
  fetchAllGroupKids,
  fetchAllGroups,
  fetchCurrentGroupMember,
  fetchGroup,
  fetchGroupBookLicenses,
  fetchGroupInvites,
  fetchGroupMembers,
  fetchGroupMetadata,
  fetchGroupPricing,
  fetchGroupSubscription,
  fetchGroupTrial,
  fetchStripeCustomerUrl,
  removeGroup,
  removeKidFromGroup,
  updateMembershipType,
} from 'data/api/groups';
import { GROUP_SORT, type MEMBERSHIP_TYPE, ROLE, SORT_DIRECTION } from 'data/enums';
import {
  type GroupEntity,
  type GroupListEntity,
  type IError,
  type KidDetailEntity,
  type RoleEntity,
  type SubscriptionEntity,
  type UrlEntity,
} from 'data/types';
import { type AxiosAPIError, type ListEntity } from 'data/utils/types';
import { type AddKidValidator, type KidsQuery, type KidValidator } from 'data/validators';
import { type GroupsQuery } from 'data/validators/query-params';
import {
  useInfiniteQuery,
  type UseInfiniteQueryOptions,
  useMutation,
  type UseMutationOptions,
  type UseMutationResult,
  useQueries,
  useQuery,
  type UseQueryOptions,
} from '@tanstack/react-query';
import { type GroupPricesEntity } from 'data/types/entities/group-prices.entity';
import { type GroupValidator } from 'data/validators/body/groups/group.validator';
import { omit } from 'remeda';
import { type MembershipQuery } from 'data/validators/query-params/membership.query';
import { type MembershipListEntity } from 'data/types/entities/membership-list.entity';
import { type InvitesQuery } from 'data/validators/query-params/invites.query';
import { type GroupInviteEntity } from 'data/types/entities/group/group-invite.entity';
import { queryKeyBuilder } from 'data/utils/hookKeys';
import { type BookLicenseListEntity } from 'data/types/entities/books/book-license-list.entity';
import { type CoachMembershipEntity } from 'data/types/entities/memberships/coach-membership.entity';
import { type GroupTrialEntity } from 'data/types/entities/group/group-trial.entity';
import { type CreateGroupValidator } from 'data/validators/body/groups/create-group.validator';
import { type GroupMetadataEntity } from 'data/types/entities/group/group-metadata.entity';
import { useAuthData } from '../utils/useAuthData';

export const groupsKeys = (() => {
  const base = 'groups';
  return {
    base: () => queryKeyBuilder(base),
    one: (id: string) => queryKeyBuilder(base, id),
    metadata: (id: string) => queryKeyBuilder(base, id, 'metadata'),
    new: () => queryKeyBuilder(base, 'new'),
    subscription: (groupId: string) => queryKeyBuilder(base, groupId, 'subscription'),
    trial: (groupId: string) => queryKeyBuilder(base, groupId, 'trial'),
    withParams: (params: GroupsQuery) => queryKeyBuilder(base, params),
    pricing: (groupId: string) => queryKeyBuilder(base, groupId, 'pricing'),
    kids: (groupId: string, params?: KidsQuery) => queryKeyBuilder(base, groupId, 'kids', params),
    members: (groupId: string, params?: MembershipQuery) => queryKeyBuilder(base, groupId, 'members', params),
    currentMember: (groupId: string) => queryKeyBuilder(base, groupId, 'currentMember'),
    invites: (groupId: string, params?: InvitesQuery) => queryKeyBuilder(base, groupId, 'invites', params),
    stripeCustomerUrl: (groupId: string) => queryKeyBuilder(base, groupId, 'stripeCustomerUrl'),
    bookLicenses: (groupId: string) => queryKeyBuilder(base, groupId, 'bookLicenses'),
    membershipTypeUpdate: () => queryKeyBuilder(base, 'membership', 'type'),
  };
})();

export function useCreateGroup(
  options?: UseMutationOptions<GroupEntity, AxiosAPIError<CreateGroupValidator>, GroupValidator>,
) {
  return useMutation({
    mutationKey: groupsKeys.new(),
    mutationFn: (body: CreateGroupValidator) => createGroup(body),
    ...options,
  });
}

export function useRemoveGroup(options?: UseMutationOptions<null, AxiosError<IError>, { groupId: string }>) {
  return useMutation({
    mutationFn: ({ groupId }: { groupId: string }) => removeGroup(groupId),
    ...options,
  });
}

export function useEditGroup(
  options?: UseMutationOptions<
    GroupEntity,
    AxiosAPIError<{ groupId: string; body: Partial<GroupValidator> }>,
    { groupId: string; body: Partial<GroupValidator> }
  >,
) {
  return useMutation({
    mutationFn: ({ groupId, body }: { groupId: string; body: Partial<GroupValidator> }) => editGroup(groupId, body),
    ...options,
  });
}

export function useFetchGroupKids(
  groupId: string,
  params?: KidsQuery,
  options?: UseQueryOptions<ListEntity<KidDetailEntity>, AxiosError<IError>>,
) {
  return useQuery({
    queryKey: groupsKeys.kids(groupId, params),
    queryFn: () => fetchAllGroupKids(groupId, params),
    ...options,
  });
}

export function useInfiniteFetchGroupKids(
  groupId: string,
  params: KidsQuery,
  options?: Omit<UseInfiniteQueryOptions<ListEntity<KidDetailEntity>, AxiosError<IError>>, 'queryKey' | 'queryFn'>,
) {
  const { limit = 12, offset = 0 } = params;

  return useInfiniteQuery({
    queryKey: groupsKeys.kids(groupId, params),
    queryFn: ({
      pageParam = {
        limit,
        offset,
        ...params,
      },
    }) => fetchAllGroupKids(groupId, pageParam),
    getNextPageParam: (lastPage, allPages) => {
      const nextOffset = allPages.reduce((prev, next) => prev + next.items.length, 0);
      if (lastPage.count - nextOffset > 0) {
        return { limit, offset: nextOffset, ...omit(params, ['offset']) };
      }
      return undefined;
    },
    ...options,
  });
}

export function useFetchAllGroups(
  params: GroupsQuery = {},
  options?: Omit<UseQueryOptions<ListEntity<GroupListEntity>, AxiosError<IError>>, 'queryKey' | 'queryFn'>,
) {
  return useQuery({
    queryKey: groupsKeys.withParams(params),
    queryFn: () => fetchAllGroups(params),
    ...options,
  });
}

export function useInfiniteFetchAllGroups(
  params: Omit<GroupsQuery, 'offset'>,
  options?: Omit<UseInfiniteQueryOptions<ListEntity<GroupListEntity>, AxiosError<IError>>, 'queryKey' | 'queryFn'>,
) {
  return useInfiniteQuery(
    groupsKeys.withParams(params),
    ({ pageParam = { limit: 10, offset: 0, sort: GROUP_SORT.NAME, direction: SORT_DIRECTION.ASC, ...params } }) =>
      fetchAllGroups(pageParam),
    {
      getNextPageParam: (lastPage, allPages) => {
        const nextOffset = allPages.reduce((prev, next) => prev + next.items.length, 0);
        if (lastPage.count - nextOffset > 0) {
          return { limit: 10, offset: nextOffset, sort: GROUP_SORT.NAME, direction: SORT_DIRECTION.ASC, ...params };
        }
        return undefined;
      },
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      ...options,
    },
  );
}

export function useFetchGroup(id: string, options?: UseQueryOptions<GroupEntity, AxiosError<IError>>) {
  return useQuery(groupsKeys.one(id), () => fetchGroup(id), {
    enabled: !!id,
    ...options,
  });
}

export function useFetchGroupMetadata(
  id: string,
  options?: Omit<UseQueryOptions<GroupMetadataEntity, AxiosError<IError>>, 'queryKey' | 'queryFn'>,
) {
  return useQuery({
    queryKey: groupsKeys.metadata(id),
    queryFn: () => fetchGroupMetadata(id),
    ...options,
  });
}

export function useFetchGroupSubscription(
  groupId: string,
  options?: UseQueryOptions<SubscriptionEntity, AxiosError<IError>>,
) {
  return useQuery(groupsKeys.subscription(groupId), () => fetchGroupSubscription(groupId), {
    ...options,
  });
}

export function useFetchGroups(ids: string[]) {
  const queries = ids.map((id) => ({
    queryKey: [groupsKeys.one(id)],
    queryFn: () => fetchGroup(id),
    enabled: !!id,
    refetchOnWindowFocus: false,
  }));
  return useQueries({ queries });
}

export type AddKidToGroupType = {
  groupId: string;
  body: AddKidValidator;
};

export function useAddKidToGroup(
  options?: Omit<UseMutationOptions<RoleEntity, AxiosAPIError<KidValidator>, AddKidToGroupType, unknown>, 'mutationFn'>,
): UseMutationResult<RoleEntity, AxiosError, AddKidToGroupType, unknown> {
  return useMutation(({ groupId, body }: AddKidToGroupType) => addKidToGroup(groupId, body), { ...options });
}

type RemoveGroupKidParams = { groupId: string; kidId: string };

export function useRemoveKidFromGroup(
  options?: Omit<UseMutationOptions<unknown, AxiosError<IError>, RemoveGroupKidParams, unknown>, 'mutationFn'>,
) {
  return useMutation(({ groupId, kidId }: RemoveGroupKidParams) => removeKidFromGroup(groupId, kidId), {
    ...options,
  });
}

export function useFetchPricing(
  groupId: string,
  options?: Omit<UseQueryOptions<GroupPricesEntity, AxiosError<IError>>, 'queryFn' | 'queryKey'>,
) {
  return useQuery({
    queryKey: groupsKeys.pricing(groupId),
    queryFn: () => fetchGroupPricing(groupId),
    ...options,
  });
}

export function useFetchGroupMembers(
  groupId: string,
  params?: MembershipQuery,
  options?: Omit<UseQueryOptions<ListEntity<MembershipListEntity>, AxiosError<IError>>, 'queryFn' | 'queryKey'>,
) {
  return useQuery({
    queryKey: groupsKeys.members(groupId, params),
    queryFn: () => fetchGroupMembers(groupId, params),
    ...options,
  });
}

export function useFetchCurrentGroupMember(
  groupId: string,
  options?: Omit<UseQueryOptions<CoachMembershipEntity, AxiosError<IError>>, 'queryFn' | 'queryKey'>,
) {
  const { role } = useAuthData();

  return useQuery({
    queryKey: groupsKeys.currentMember(groupId),
    queryFn: () => fetchCurrentGroupMember(groupId),
    useErrorBoundary: false,
    ...options,
    enabled: role !== ROLE.ADMIN && options?.enabled,
  });
}

export function useFetchGroupInvites(
  groupId: string,
  params?: InvitesQuery,
  options?: UseQueryOptions<ListEntity<GroupInviteEntity>, AxiosError<IError>>,
) {
  return useQuery({
    queryKey: groupsKeys.invites(groupId, params),
    queryFn: () => fetchGroupInvites(groupId, params),
    ...options,
  });
}

export function useUpdateMembershipType(
  options?: UseMutationOptions<
    null,
    AxiosAPIError<{ type: MEMBERSHIP_TYPE }>,
    {
      groupId: string;
      membershipId: string;
      type: MEMBERSHIP_TYPE;
    }
  >,
) {
  return useMutation({
    mutationKey: groupsKeys.membershipTypeUpdate(),
    mutationFn: ({ groupId, membershipId, type }) => updateMembershipType(groupId, membershipId, type),
    ...options,
  });
}

type RemoveGroupMembershipParams = { groupId: string; roleId: string };

export function useRemoveGroupMembership(
  options?: Omit<UseMutationOptions<unknown, AxiosError<IError>, RemoveGroupMembershipParams>, 'mutationFn'>,
) {
  return useMutation({
    mutationFn: ({ groupId, roleId }: RemoveGroupMembershipParams) => deleteGroupMembership(groupId, { roleId }),
    ...options,
  });
}

export function useFetchStripeCustomerUrl(
  groupId: string,
  options?: Omit<UseQueryOptions<UrlEntity, AxiosError<IError>>, 'queryFn' | 'queryKey'>,
) {
  return useQuery({
    queryKey: groupsKeys.stripeCustomerUrl(groupId),
    queryFn: () => fetchStripeCustomerUrl(groupId),
    ...options,
  });
}

export function useFetchGroupBookLicenses(
  groupId: string,
  options?: Omit<UseQueryOptions<ListEntity<BookLicenseListEntity>, AxiosError<IError>>, 'queryFn' | 'queryKey'>,
) {
  return useQuery({
    queryKey: groupsKeys.bookLicenses(groupId),
    queryFn: () => fetchGroupBookLicenses(groupId),
    ...options,
  });
}

export function useFetchGroupTrial(
  groupId: string,
  options?: Omit<UseQueryOptions<GroupTrialEntity, AxiosError<IError>>, 'queryFn' | 'queryKey'>,
) {
  return useQuery({
    queryKey: groupsKeys.trial(groupId),
    queryFn: () => fetchGroupTrial(groupId),
    ...options,
  });
}
