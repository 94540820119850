import React, { useEffect, useRef } from 'react';

import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { create } from 'zustand';
import { ROLE } from 'data/enums';
import { type Language, ROLE_NAME, supportedLanguages } from 'app/constants/data';
import { LOCAL_STORAGE_LANGUAGE_KEY } from 'app/constants/store';
import {
  facebookLink,
  helpDeskLinks,
  instagramLink,
  joinPath,
  linkedInLink,
  youtubeLink,
} from 'app/constants/url/shared';

import cx from 'classnames';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import LanguageIcon from '@mui/icons-material/Language';
import ButtonBase from '@mui/material/ButtonBase';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { Drawer, icons } from '@coach/ui';
import { useAuthData } from 'data/hooks';
import Select from '@mui/material/Select';
import { useActiveGroupId, useGroupActions } from 'data/stores/group-store';
import { hasTouchScreen } from 'app/utils/helpers/browser-apis/browser-apis';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import useBreakpoints from 'app/utils/hooks/useBreakpoints';
import { useCoachGroups } from 'app/utils/hooks/useCoachGroups';
import { useWinterMode } from 'data/atoms/feature-flags';
import styles from './styles.module.css';
import { TrialBanner } from './TrialBanner';

interface LayoutProps {
  content?: React.ReactNode;
  sidebar?: React.ReactNode;
  header?: React.ReactNode;
  logoPath: string;
}

type LayoutStore = {
  isMobileDrawerOpen: boolean;
  isDrawerSquashed: boolean;
  isSidebarHidden: boolean;
  toggleMobileDrawer: () => void;
  toggleDrawerSquash: () => void;
  setMobileDrawerOpen: (isDrawerOpen: boolean) => void;
  setDrawerSquashed: (isDrawerSquashed: boolean) => void;
  setSidebarHidden: (isSidebarHidden: boolean) => void;
};

export const useLayoutStore = create<LayoutStore>((set) => ({
  isMobileDrawerOpen: false,
  isDrawerSquashed: false,
  isSidebarHidden: false,
  toggleMobileDrawer: () => set((state) => ({ isMobileDrawerOpen: !state.isMobileDrawerOpen })),
  toggleDrawerSquash: () => set((state) => ({ isDrawerSquashed: !state.isDrawerSquashed })),
  setMobileDrawerOpen: (isDrawerOpen: boolean) => set({ isMobileDrawerOpen: isDrawerOpen }),
  setDrawerSquashed: (isDrawerSquashed: boolean) => set({ isDrawerSquashed }),
  setSidebarHidden: (isSidebarHidden: boolean) => set({ isSidebarHidden }),
}));

const contentWidth = 'md:w-[calc(100%-var(--sidebar-width))]';

const CREATE_GROUP_OPTION_VALUE = 'create-group';

export const Layout = ({ content, sidebar, header, logoPath }: LayoutProps) => {
  const { t, i18n } = useTranslation();
  const layout = useRef<HTMLDivElement | null>(null);
  const history = useHistory();
  const { location } = history;
  const { isMd } = useBreakpoints();
  const isWinterMode = useWinterMode();

  const {
    isMobileDrawerOpen,
    isDrawerSquashed,
    isSidebarHidden,
    toggleMobileDrawer,
    toggleDrawerSquash,
    setMobileDrawerOpen,
  } = useLayoutStore();

  const groupActions = useGroupActions();

  const { data: groupList, isLoading: areGroupsLoading } = useCoachGroups();

  const activeGroupId = useActiveGroupId({ fallback: false });
  const { role } = useAuthData();

  useEffect(() => {
    /**
     * Squashed drawer is not appropriate for mobile devices
     */
    if (isMobileDrawerOpen && isDrawerSquashed) {
      toggleDrawerSquash();
    }
  }, [isMobileDrawerOpen]);

  /**
   * Improve the UX by closing the drawer when the user navigates to a new page
   */
  useEffect(() => {
    setMobileDrawerOpen(false);
  }, [location]);

  const handleLanguageChange = (newLang: Language) => {
    i18n.changeLanguage(newLang).then(() => {
      localStorage.setItem(LOCAL_STORAGE_LANGUAGE_KEY, newLang);
    });
  };

  const SelectOption = hasTouchScreen() ? 'option' : MenuItem;

  const drawer = (
    <nav className="flex h-full flex-col overflow-x-hidden">
      <Toolbar className="mt-3 min-h-[24px] justify-end md:mt-0 md:min-h-[var(--header-height)] md:px-0">
        <IconButton
          className="md:hidden"
          color="inherit"
          aria-label="close drawer"
          edge="end"
          onClick={toggleMobileDrawer}
        >
          <CloseIcon />
        </IconButton>
        <Link
          className={cx(
            'hidden h-full items-center justify-start bg-primary-light pl-4 text-black no-underline md:flex',
            isDrawerSquashed ? 'w-0' : 'w-full',
            isWinterMode && styles.winterMode,
          )}
          to={logoPath}
        >
          <div className="flex h-full items-center justify-start">{icons.logoMain}</div>
        </Link>
      </Toolbar>

      {sidebar}

      <List className="mt-auto pt-4">
        <ListItem className={cx(role !== ROLE.COACH && 'hidden')}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="active-group">{t('switchTeam')}</InputLabel>
            <Select
              size="small"
              onChange={(event) => {
                const groupId = event.target.value;

                if (groupId === CREATE_GROUP_OPTION_VALUE) {
                  history.push(joinPath);
                  return;
                }

                if (!groupId || typeof groupId !== 'string') {
                  console.error('No group selected');
                  return;
                }

                groupActions.setActiveGroupId(groupId);
              }}
              variant="outlined"
              name="group"
              label={t('switchTeam')}
              labelId="active-group"
              value={activeGroupId}
              native={hasTouchScreen()}
              disabled={areGroupsLoading}
            >
              {groupList?.items.map((group) => (
                <SelectOption key={group.id} value={group.id}>
                  {group.name}
                </SelectOption>
              ))}
              <SelectOption value={CREATE_GROUP_OPTION_VALUE}>+ {t('new')}</SelectOption>
            </Select>
          </FormControl>
        </ListItem>
        <ListItem className="gap-3 md:hidden">
          <ListItemIcon className="-ml-[2px] min-w-[12px]">
            <LanguageIcon />
          </ListItemIcon>
          <div className="flex gap-3">
            {supportedLanguages.map((lang) => {
              const isCurrentLanguage = lang === i18n.language;
              return (
                <ButtonBase
                  key={lang}
                  className={cx(
                    'h-[32px] w-[32px] rounded-sm uppercase transition-colors hover:bg-gray-100',
                    isCurrentLanguage &&
                      'border-2 border-solid border-primary-dark bg-primary-light hover:bg-primary-light',
                  )}
                  onClick={() => handleLanguageChange(lang)}
                >
                  <Typography className={cx('uppercase', isCurrentLanguage && 'text-primary-dark')}>{lang}</Typography>
                </ButtonBase>
              );
            })}
          </div>
        </ListItem>
        <ListItem button className="gap-3 rounded-r-3xl">
          <ListItemIcon className="-ml-[2px] min-w-[12px]">{icons.help}</ListItemIcon>
          <a
            href={helpDeskLinks[i18n.language]}
            target="_blank"
            rel="noreferrer noopener"
            className="text-black"
            data-intercom-target="help-link"
          >
            <Typography>{t('help')}</Typography>
          </a>
        </ListItem>
        <ListItem
          className="-ml-[2px] gap-5"
          data-intercom-target="sidebar-community"
          data-sentry-breadcrumb="sidebar-community"
        >
          {[
            {
              icon: icons.facebook,
              href: facebookLink,
            },
            {
              icon: icons.instagram,
              href: instagramLink,
            },
            {
              icon: icons.linkedIn,
              href: linkedInLink,
            },
            {
              icon: icons.youtube,
              href: youtubeLink,
            },
          ].map((social) => (
            <a key={social.href} href={social.href} target="_blank" rel="noreferrer noopener" className="text-black">
              <ListItemIcon className="min-w-[12px]">{social.icon}</ListItemIcon>
            </a>
          ))}
        </ListItem>
      </List>
    </nav>
  );

  return (
    <div
      id="layout"
      ref={layout}
      data-role={ROLE_NAME[role]}
      className={cx(
        'flex bg-backgroundColor',
        isDrawerSquashed && 'squashed-sidebar',
        isSidebarHidden && 'hidden-sidebar',
      )}
    >
      <div
        className={cx(
          // z-index works only with + 11 and more, no idea why
          'fixed top-0 z-[calc(var(--drawer-z-index)+11)] w-full md:ml-[var(--sidebar-width)]',
          contentWidth,
        )}
      >
        {header}
      </div>
      {sidebar && (
        <aside className="md:w-[var(--sidebar-width)] md:shrink-0">
          {!isMd ? (
            <Drawer
              variant="persistent"
              container={layout.current}
              open={isMobileDrawerOpen}
              onClose={toggleMobileDrawer}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              classes={{
                paper: 'w-[var(--sidebar-width)]',
              }}
              transitionDuration={300}
            >
              {drawer}
            </Drawer>
          ) : (
            <Drawer
              variant="permanent"
              open
              classes={{
                paper: cx('transition-width', styles.desktopSidebarPaper, isDrawerSquashed && styles.squashed),
              }}
            >
              {drawer}
            </Drawer>
          )}
        </aside>
      )}
      <main className={cx('mt-[var(--header-height)] max-w-full grow', contentWidth)}>
        <TrialBanner />
        <div className={cx('p-4')}>{content}</div>
      </main>
    </div>
  );
};
