import * as api from 'data/api';
import {
  deleteRole,
  fetchAllGroupsByCoach,
  fetchCoachClasses,
  fetchKidsReportsByCoach,
  fetchRole,
  fetchRolesForCurrentCoach,
} from 'data/api';
import { queryKeyBuilder } from 'data/utils/hookKeys';
import { kidsKeys } from 'data/hooks/queries/kids';
import {
  type GroupsQuery,
  type KidsQuery,
  type KidsReportsQuery,
  type PaginationQuery,
  type RolesQuery,
} from 'data/validators/query-params';
import { type GroupBaseEntity, type GroupEntity, type IError, type RoleEntity } from 'data/types/entities';
import { type AxiosAPIError, type ListEntity } from 'data/utils/types';
import {
  useInfiniteQuery,
  type UseInfiniteQueryOptions,
  useMutation,
  type UseMutationOptions,
  useQueries,
  useQuery,
  type UseQueryOptions,
} from '@tanstack/react-query';
import { KID_SORT, ROLE, SORT_DIRECTION } from 'data/enums';
import { type AxiosError } from 'axios';
import { type RoleQuery } from 'data/validators/query-params/role.query';
import { type RoleDetailsEntity } from 'data/types/entities/role-details.entity';

export const roleKeys = (() => {
  const base = 'role';
  return {
    allByAdmin: (params?: RolesQuery) => queryKeyBuilder(base, 'allByAdmin', params),
    all: (params: Omit<RolesQuery, 'offset'>) => queryKeyBuilder(base, params),
    profile: (roleId: string) => queryKeyBuilder(base, 'profile', roleId),
    details: (roleId: string, params?: RoleQuery) => queryKeyBuilder(base, 'details', roleId, params),
    groups: (roleId: string, params?: GroupsQuery) => queryKeyBuilder(base, roleId, 'groups', params),
    classes: (roleId: string, params?: PaginationQuery) => queryKeyBuilder(base, roleId, 'classes', params),
    kids: (id: string, params: Omit<KidsQuery, 'offset'>) => queryKeyBuilder(base, 'kids', id, params),
    kidsReports: (roleId: string, params: KidsReportsQuery) => queryKeyBuilder(base, 'kidsReports', roleId, params),
    delete: (roleId?: string) => queryKeyBuilder(base, 'delete', roleId),
  };
})();

export const useFetchProfilesByRole = (ids: string[]) => {
  const queries = ids.map((roleId) => ({
    queryKey: [roleKeys.profile(roleId)],
    queryFn: () => fetchRole(roleId),
    enabled: !!roleId,
    refetchOnWindowFocus: false,
  }));
  return useQueries({ queries });
};

export function useFetchAllRoles(
  params?: RolesQuery,
  options?: UseQueryOptions<ListEntity<RoleEntity>, AxiosError<IError>>,
) {
  return useQuery({
    queryKey: roleKeys.allByAdmin(params),
    queryFn: () => api.fetchAllRoles(params),
    ...options,
  });
}

export const useInfiniteFetchAllRoles = (
  params: RolesQuery,
  options?: Omit<UseInfiniteQueryOptions<ListEntity<RoleEntity>, AxiosError<IError>>, 'queryKey' | 'queryFn'>,
) =>
  useInfiniteQuery({
    queryKey: roleKeys.allByAdmin(params),
    queryFn: ({ pageParam = { limit: 10, offset: 0, ...params } }) => api.fetchAllRoles(pageParam),

    getNextPageParam: (lastPage, allPages) => {
      const nextOffset = allPages.reduce((prev, next) => prev + next.items.length, 0);
      if (lastPage.count - nextOffset > 0) {
        return { limit: 10, offset: nextOffset, ...params };
      }
      return undefined;
    },
    refetchOnWindowFocus: false,
    ...options,
  });

export function useFetchRole<P extends RoleQuery>(
  roleId: string,
  params?: P,
  options?: Omit<UseQueryOptions<RoleDetailsEntity<P['populate']>, AxiosAPIError<RoleQuery>>, 'queryKey' | 'queryFn'>,
) {
  return useQuery({
    queryKey: roleKeys.details(roleId, params),
    queryFn: () => fetchRole(roleId, params),
    ...options,
  });
}

export const useFetchAllKidsForCurrentCoach = (
  roleId: string,
  params: RolesQuery,
  options?: Omit<UseQueryOptions<ListEntity<RoleEntity>, Error>, 'queryKey' | 'queryFn'>,
) =>
  useQuery<ListEntity<RoleEntity>, Error>(
    kidsKeys.list(roleId, params),
    () => api.fetchRolesForCurrentCoach({ ...params, types: [ROLE.KID] }),
    {
      enabled: !!params,
      ...options,
    },
  );

export const useInfiniteFetchAllRolesByCoach = (
  params: Omit<RolesQuery, 'offset'>,
  options?: Omit<UseInfiniteQueryOptions<ListEntity<RoleEntity>, Error>, 'queryKey' | 'queryFn'>,
) =>
  useInfiniteQuery<ListEntity<RoleEntity>, Error>(
    roleKeys.all(params),
    ({
      pageParam = {
        limit: 10,
        offset: 0,
        ...params,
      },
    }) => fetchRolesForCurrentCoach(pageParam),
    {
      getNextPageParam: (lastPage, allPages) => {
        const nextOffset = allPages.reduce((prev, next) => prev + next.items.length, 0);
        if (lastPage.count - nextOffset > 0) {
          return {
            limit: 10,
            offset: nextOffset,
            ...params,
          };
        }
        return undefined;
      },
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      ...options,
    },
  );

export function useFetchAllGroupsByCoach(
  roleId: string,
  params: GroupsQuery = {},
  options?: UseQueryOptions<ListEntity<GroupEntity>, AxiosError<IError>> | undefined,
) {
  return useQuery({
    queryKey: roleKeys.groups(roleId, params),
    queryFn: () => fetchAllGroupsByCoach(roleId, params),
    ...options,
  });
}

export function useFetchCoachClasses(
  roleId: string,
  params?: PaginationQuery,
  options?: UseQueryOptions<ListEntity<GroupBaseEntity>, AxiosError<IError>>,
) {
  return useQuery({
    queryKey: roleKeys.classes(roleId, params),
    queryFn: () => fetchCoachClasses(roleId, params),
    ...options,
  });
}

export const useInfiniteFetchAllGroupsByCoach = (
  roleId: string,
  params: Omit<GroupsQuery, 'offset'> = {},
  options?: Omit<UseInfiniteQueryOptions<ListEntity<GroupEntity>, Error>, 'queryKey' | 'queryFn'>,
) =>
  useInfiniteQuery<ListEntity<GroupEntity>, Error>(
    roleKeys.groups(roleId, params),
    ({
      pageParam = {
        limit: 10,
        offset: 0,
        ...params,
      },
    }) => fetchAllGroupsByCoach(roleId, pageParam),
    {
      getNextPageParam: (lastPage, allPages) => {
        const nextOffset = allPages.reduce((prev, next) => prev + next.items.length, 0);
        if (lastPage.count - nextOffset > 0) {
          return {
            limit: 10,
            offset: nextOffset,
            ...params,
          };
        }
        return undefined;
      },
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      ...options,
    },
  );

type FetchAllKidsByCoachOptions = Omit<
  UseQueryOptions<ListEntity<RoleEntity>, AxiosAPIError<KidsQuery>>,
  'queryKey' | 'queryFn'
>;

export const useFetchAllKidsByCoach = (roleId: string, params?: KidsQuery, options?: FetchAllKidsByCoachOptions) =>
  useQuery(roleKeys.kids(roleId, params || {}), () => api.fetchAllKidsByCoach(roleId, params), options);

export const useInfiniteFetchAllKidsByCoach = (
  roleId: string,
  params?: KidsQuery,
  options?: Omit<UseInfiniteQueryOptions<ListEntity<RoleEntity>, Error>, 'queryKey' | 'queryFn'>,
) =>
  useInfiniteQuery(
    roleKeys.kids(roleId, params || {}),
    ({ pageParam = { limit: 10, offset: 0, sort: KID_SORT.NAME, direction: SORT_DIRECTION.ASC, ...params } }) =>
      api.fetchAllKidsByCoach(roleId, pageParam),
    {
      getNextPageParam: (lastPage, allPages) => {
        const nextOffset = allPages.reduce((prev, next) => prev + next.items.length, 0);
        if (lastPage.count - nextOffset > 0) {
          return { limit: 10, offset: nextOffset, sort: KID_SORT.NAME, direction: SORT_DIRECTION.ASC, ...params };
        }
        return undefined;
      },
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      ...options,
    },
  );

type FetchKidsReportsByCoachOptions = Omit<
  UseQueryOptions<ListEntity<RoleEntity>, AxiosError<IError>>,
  'queryKey' | 'queryFn'
>;

export const useFetchKidsReportsByCoach = (
  roleId: string,
  params?: KidsReportsQuery,
  options?: FetchKidsReportsByCoachOptions,
) =>
  useQuery<ListEntity<RoleEntity>>(
    roleKeys.kidsReports(roleId, { offset: 0, limit: 100, ...params }),
    () => fetchKidsReportsByCoach(roleId, { offset: 0, limit: 100, ...params }),
    options,
  );

type DeleteRoleVariables = {
  roleId: string;
};

export function useDeleteRole(
  options?: Omit<UseMutationOptions<null, AxiosError<IError>, DeleteRoleVariables>, 'mutationFn'>,
) {
  return useMutation({
    mutationKey: roleKeys.delete(),
    mutationFn: ({ roleId }: DeleteRoleVariables) => deleteRole(roleId),
    ...options,
  });
}
