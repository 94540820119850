import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { icons, Select } from '@coach/ui';
import cx from 'classnames';
import { languagesShort } from 'app/constants/data';
import { LOCAL_STORAGE_LANGUAGE_KEY } from 'app/constants/store';
import { SelectProps } from '@coach/ui/components/form';
import { useStyles } from './styles';

type Props = Omit<SelectProps, 'options' | 'onChange' | 'value'>;

const LanguageSelect = ({ className, style, ...props }: Props) => {
  const { i18n } = useTranslation();
  const s = useStyles();
  const [value, setValue] = React.useState(i18n.language);

  useEffect(() => {
    setValue(i18n.language);
    localStorage.setItem(LOCAL_STORAGE_LANGUAGE_KEY, i18n.language);
  }, [i18n.language]);

  const handleChangeLanguage = ({ target: { value: newLang } }) => {
    i18n.changeLanguage(newLang);
    localStorage.setItem(LOCAL_STORAGE_LANGUAGE_KEY, newLang);
  };

  return (
    <div className={cx(s.wrapper, className)} style={style}>
      {icons.language}
      <Select options={languagesShort} className={s.root} onChange={handleChangeLanguage} value={value} {...props} />
    </div>
  );
};

export default LanguageSelect;
