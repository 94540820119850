import { useMutation, type UseMutationOptions, useQuery, UseQueryOptions } from '@tanstack/react-query';
import {
  authorizeWithSSO,
  generatePasscodeResetURL,
  generatePasswordResetURL,
  getAuthenticatedUser,
  impersonateRole,
  sendResetPasscodeEmail,
  sendResetPasswordEmail,
  signIn,
  signOut,
  signUp,
} from 'data/api';

import { type AUTH_PROVIDER_TYPE } from 'data/enums';

import { type EmailValidator, type SignInValidator, type SignUpValidator } from 'data/validators/body';
import { type AxiosError } from 'axios';
import { type APIValidationError, type AxiosAPIError } from 'data/utils/types';
import { type IError, type RoleEntity, type UrlEntity, UserEntity } from 'data/types';
import type { SSOAuthorizationValidator } from 'data/validators/body/sso-authorization.validator';
import { queryKeyBuilder } from 'data/utils/hookKeys';

export const authKeys = (() => {
  const base = 'auth';
  return {
    base: () => queryKeyBuilder(base),
    signUp: () => queryKeyBuilder(base, 'signUp'),
    signIn: () => queryKeyBuilder(base, 'signIn'),
    signOut: () => queryKeyBuilder(base, 'signOut'),
    ssoAuthorization: () => queryKeyBuilder(base, 'ssoAuthorization'),
    resetPassword: () => queryKeyBuilder(base, 'resetPassword'),
    resetPasscode: () => queryKeyBuilder(base, 'resetPasscode'),
    resetPasswordURL: () => queryKeyBuilder(base, 'resetPasscodeURL'),
    resetPasscodeURL: () => queryKeyBuilder(base, 'resetPasswordURL'),
    impersonation: () => queryKeyBuilder(base, 'impersonation'),
    user: () => queryKeyBuilder(base, 'user'),
  };
})();

export function useSignUp(
  options?: Omit<
    UseMutationOptions<RoleEntity, AxiosAPIError<SignUpValidator>, SignUpValidator, unknown>,
    'mutationKey' | 'mutationFn'
  >,
) {
  return useMutation(authKeys.signUp(), signUp, options);
}

type UseSignInOptions = Omit<
  UseMutationOptions<RoleEntity, AxiosError<IError>, SignInValidator, unknown>,
  'mutationFn'
>;

export function useSignIn(options?: UseSignInOptions) {
  return useMutation(authKeys.signIn(), (credentials: SignInValidator) => signIn(credentials), options);
}

export function useSignOut(options?: UseMutationOptions) {
  return useMutation({
    mutationKey: authKeys.signOut(),
    mutationFn: () => signOut(),
    ...options,
  });
}

type UseSSOAuthorizationArgs = {
  provider: AUTH_PROVIDER_TYPE;
  body: SSOAuthorizationValidator;
  language?: string;
};

type UseSSOAuthorizationOptions = Omit<
  UseMutationOptions<RoleEntity, AxiosError<IError>, UseSSOAuthorizationArgs, unknown>,
  'mutationFn'
>;

export function useSSOAuthorization(options?: UseSSOAuthorizationOptions) {
  return useMutation(
    authKeys.ssoAuthorization(),
    ({ provider, body, language }: UseSSOAuthorizationArgs) => authorizeWithSSO(provider, body, language),
    options,
  );
}

type UseSendResetSecretIdentifierEmailOptions = Omit<
  UseMutationOptions<unknown, AxiosError<APIValidationError<EmailValidator>>, EmailValidator, unknown>,
  'mutationFn' | 'mutationKey'
>;

export const useSendResetPasswordEmail = (options?: UseSendResetSecretIdentifierEmailOptions) =>
  useMutation(authKeys.resetPassword(), sendResetPasswordEmail, options);

export const useSendResetPasscodeEmail = (options?: UseSendResetSecretIdentifierEmailOptions) =>
  useMutation(authKeys.resetPasscode(), sendResetPasscodeEmail, options);

type UseGenerateCredentialResetURLOptions = Omit<
  UseMutationOptions<
    UrlEntity,
    AxiosError<IError>,
    {
      userId: string;
    }
  >,
  'mutationFn'
>;

export function useGenerateResetPasswordURL(options?: UseGenerateCredentialResetURLOptions) {
  return useMutation({
    mutationKey: authKeys.resetPasswordURL(),
    mutationFn: ({ userId }: { userId: string }) => generatePasswordResetURL(userId),
    ...options,
  });
}

export function useGenerateResetPasscodeURL(options?: UseGenerateCredentialResetURLOptions) {
  return useMutation({
    mutationKey: authKeys.resetPasscodeURL(),
    mutationFn: ({ userId }: { userId: string }) => generatePasscodeResetURL(userId),
    ...options,
  });
}

type UseImpersonateRoleOptions = Omit<
  UseMutationOptions<RoleEntity, AxiosError<IError>, { roleId: string }, unknown>,
  'mutationFn'
>;

export const useImpersonateRole = (options?: UseImpersonateRoleOptions) =>
  useMutation({
    mutationKey: authKeys.impersonation(),
    mutationFn: ({ roleId }: { roleId: string }) => impersonateRole(roleId),
    ...options,
  });

export function useFetchAuthenticatedUser(
  options?: Omit<UseQueryOptions<null, AxiosError<IError>, UserEntity>, 'queryFn' | 'queryKey'>,
) {
  return useQuery({
    queryKey: authKeys.user(),
    queryFn: () => getAuthenticatedUser(),
    ...options,
  });
}
